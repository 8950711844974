import React, { Component } from 'react';
import Menu from 'react-select';
import { Form, Col, FormControl } from 'react-bootstrap';
import LoaderButton from '../components/LoaderButton';
import { API, Auth } from 'aws-amplify';
import qs from 'query-string';
import { arrayUnique, sortFun } from '../utils/CategoryUtils';
import PropTypes from 'prop-types';

import './Categories.css';

export default class Categories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUpdating: false,
      oldName: '',
      newName: '',
      oldNameOptions: [],
      mainIngredientOptions: [],
      sidesOptions: [],
      bakeOptions: [],
      todOptions: [],
      torOptions: [],
      meta: null,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.history.push('/login');
      return;
    }

    const identityId = (await Auth.currentUserCredentials()).identityId;
    sessionStorage.setItem('currentIdentityId', identityId);

    const searchString = qs.parse(this.props.location.search).searchString;
    if (searchString) this.setState({ searchString });

    const meta = await this.getMetadata();

    this.setState({
      oldNameOptions: [
        {
          label: 'Huvudingredienser',
          options: meta.mainIngredient
            .map((val) => {
              return { category: 'mainIngredient', value: val, label: val };
            })
            .sort(sortFun),
        },
        {
          label: 'Typ av recept',
          options: meta.typeOfRecipe
            .map((val) => {
              return { category: 'typeOfRecipe', value: val, label: val };
            })
            .sort(sortFun),
        },
        {
          label: 'Typ av rätt',
          options: meta.typeOfDish
            .map((val) => {
              return { category: 'typeOfDish', value: val, label: val };
            })
            .sort(sortFun),
        },
        {
          label: 'Bakning',
          options: meta.bake
            .map((val) => {
              return { category: 'bake', value: val, label: val };
            })
            .sort(sortFun),
        },
        {
          label: 'Tillbehör',
          options: meta.sides
            .map((val) => {
              return { category: 'sides', value: val, label: val };
            })
            .sort(sortFun),
        },
      ],
    });
  }

  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  getMetadata = async () => {
    const meta = this.state.meta
      ? this.state.meta
      : await API.get('recipes', '/metadata');
    if (!this.state.meta) this.setState({ meta });

    return {
      sides: arrayUnique(
        (meta.categories.sides || []).concat(meta.defaultCategories.sides)
      ),
      bake: arrayUnique(
        (meta.categories.bake || []).concat(meta.defaultCategories.bake)
      ),
      mainIngredient: arrayUnique(
        (meta.categories.mainIngredient || []).concat(
          meta.defaultCategories.mainIngredient
        )
      ),
      typeOfDish: arrayUnique(
        (meta.categories.typeOfDish || []).concat(
          meta.defaultCategories.typeOfDish
        )
      ),
      typeOfRecipe: arrayUnique(
        (meta.categories.typeOfRecipe || []).concat(
          meta.defaultCategories.typeOfRecipe
        )
      ),
    };
  };

  validateForm() {
    return (
      this.state.oldName &&
      this.state.oldName.category &&
      this.state.oldName.value &&
      this.state.newName.length > 0
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleOptionsChange = async (newValue) => {
    console.group('Value Changed');
    console.log('nv', newValue);
    await this.setState({ oldName: newValue ? newValue : [] });
    console.log('new state', this.state.oldName);
    console.groupEnd();
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isUpdating: true });
    await this.performUpdate();
  };

  handleAbort = async (event) => {
    event.preventDefault();

    this.props.history.goBack();
  };

  performUpdate = async () => {
    if (
      this.state.meta.defaultCategories[this.state.oldName.category].includes(
        this.state.oldName.label
      )
    ) {
      const confirmed = window.confirm(
        `${this.state.oldName.label} är en standardkategori, vill du ändå byta namn till ${this.state.newName}?`
      );
      if (!confirmed) {
        this.setState({ isUpdating: false });
        return;
      }
    }

    try {
      const recipes = (await this.searchRecipes(this.state.oldName)).recipes;

      if (recipes.length === 0) {
        alert('Inga recept finns med den kategorin, uppdateringen avbryts!');
        this.setState({ isUpdating: false });
        return;
      }

      const confirmed = window.confirm(
        `${recipes.length} recept kommer påverkas av den här ändringen, är du säker på att du vill fortsätta?`
      );

      if (!confirmed) {
        this.setState({ isUpdating: false });
        return;
      }

      await this.updateCategory({
        category: this.state.oldName.category.trim(),
        oldName: this.state.oldName.label.trim(),
        newName: this.state.newName.trim(),
      });
      this.setState({ isUpdating: false });
      this.props.history.goBack();
    } catch (e) {
      alert(e);
      this.setState({ isUpdating: false });
      return;
    }
  };

  async updateCategory(params) {
    console.log('updating with', params);
    return API.post('recipes', '/category', {
      body: params,
    });
  }

  async searchRecipes(oldName) {
    const queryStringParameters = { [oldName.category]: oldName.value };

    return API.get('recipes', '/recipes', {
      queryStringParameters,
    });
  }

  renderRecipes() {
    return (
      <div className="categoryChange">
        <h4>Byt namn på kategori</h4>
        <div>
          <Form
            onSubmit={this.handleSubmit}
            onReset={this.handleAbort}
            className="UpdateForm"
          >
            <Form.Row>
              <Form.Group as={Col} controlId="old" xs="12" md="6" lg="4">
                <Form.Label>Gammalt namn</Form.Label>
                <Menu
                  name="oldName"
                  onChange={this.handleOptionsChange}
                  value={this.state.oldName}
                  options={this.state.oldNameOptions}
                  placeholder="Välj"
                  closeMenuOnSelect={true}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="newName" xs="12" md="6" lg="4">
                <Form.Label>Nytt namn</Form.Label>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.newName}
                  type="text"
                  placeholder="Uppdatera"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs="12" md="2" controlId="updateButton">
                <LoaderButton
                  block
                  variant="info"
                  // bsSize="large"
                  disabled={!this.validateForm()}
                  type="submit"
                  isLoading={this.state.isUpdating}
                  text="Uppdatera"
                />
              </Form.Group>
              <Form.Group as={Col} xs="12" md="2" controlId="abortButton">
                <LoaderButton
                  block
                  variant="info"
                  // bsSize="large"
                  disabled={false}
                  type="reset"
                  text="Avbryt"
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </div>
        <hr />
      </div>
    );
  }

  render() {
    return <div className="Categories">{this.renderRecipes()}</div>;
  }
}

Categories.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  isAuthenticated: PropTypes.any,
};
