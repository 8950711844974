import React, { Component } from 'react';
import { API, Storage, Auth } from 'aws-amplify';
import LoaderButton from '../components/LoaderButton';
import Linkify from 'react-linkify';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { IoIosHeartEmpty, IoIosHeart, IoMdCopy } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';
import copy from 'copy-to-clipboard';
import FeatureToggles from '../utils/FeatureToggles';
import PropTypes from 'prop-types';

import './Recipes.css';

export default class Recipe extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isDeleting: null,
      isUpdating: false,
      recipe: null,
      imageURL: null,
      fav: null,
      copying: false,
      searchAllEnabled: false,
      ownRecipe: false,
      ownerUsername: '',
    };
  }

  async componentDidMount() {
    let auth = true;
    try {
      auth = this.props.isAuthenticated && (await Auth.currentSession());
    } catch (err) {
      auth = false;
    }
    if (!auth) {
      this.props.history.push('/login');
      return;
    }
    try {
      let imageURL = undefined;
      if (!sessionStorage.getItem('currentIdentityId')) {
        const identityId = (await Auth.currentUserCredentials()).identityId;
        sessionStorage.setItem('currentIdentityId', identityId);
      }
      this.setState({
        ownRecipe:
          sessionStorage.getItem('currentIdentityId') ===
          this.props.match.params.userId,
        searchAllEnabled: await FeatureToggles.feature('searchAll'),
      });
      const [recipe, username] = await Promise.all([
        this.getRecipe(),
        this.getUsername(),
      ]);
      const { image } = recipe;

      if (image) {
        if (typeof image === 'string') {
          const imageParts = image.split('/');
          imageURL = await Storage.get(imageParts.pop(), {
            level: 'protected',
            identityId: imageParts.pop(),
          });
        }
      }

      const favorites = this.state.ownRecipe
        ? (await API.get('recipes', '/metadata')).favorites
        : [];

      this.setState({
        recipe,
        imageURL,
        fav: favorites.find((item) => item === this.props.match.params.id),
        ownerUsername: username,
      });
    } catch (e) {
      alert(`Det gick inte att hämta receptet på grund av: \n${e}`);
      this.props.history.goBack();
    }
  }

  getUsername = async () => {
    return this.state.ownRecipe
      ? ''
      : (
          await API.get('recipes', '/username', {
            queryStringParameters: {
              username: this.props.match.params.userId,
            },
          })
        ).userName;
  };

  getRecipe() {
    if (this.state.ownRecipe) {
      return API.get('recipes', `/recipes/${this.props.match.params.id}`);
    } else {
      const queryStringParameters = { userId: this.props.match.params.userId };
      return API.get('recipes', `/recipes/${this.props.match.params.id}`, {
        queryStringParameters,
      });
    }
  }

  handleDelete = async (event) => {
    event.preventDefault();

    const confirmed = window.confirm(
      'Är du säker på att du vill ta bort det här receptet?'
    );

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    sessionStorage.removeItem('recipesFetchTime');
    sessionStorage.removeItem('recipes');
    this.props.history.push('/');

    return await API.del('recipes', `/recipes/${this.props.match.params.id}`);
  };

  handleUpdate = async (event) => {
    event.preventDefault();

    this.setState({ isUpdating: true });

    this.props.history.push(
      `/recipes/${this.props.match.params.userId}/${this.props.match.params.id}/update`
    );
  };

  handleAdd = async (event) => {
    event.preventDefault();

    this.setState({ isUpdating: true });
    try {
      const result = await API.post(
        'recipes',
        `/recipes/${this.props.match.params.id}/copy`,
        {
          body: {
            ownerId: this.props.match.params.userId,
          },
        }
      );
      sessionStorage.removeItem('recipesFetchTime');
      sessionStorage.removeItem('recipes');
      this.setState({ isUpdating: false });
      this.props.history.push(
        `/recipes/${sessionStorage.getItem('currentIdentityId')}/${
          result.recipeId
        }`
      );
    } catch (err) {
      if (err.response && err.response.data && err.response.data.Error)
        alert(err.response.data.Error);
      else alert(err);
      this.setState({ isUpdating: false });
    }
  };

  componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  toggleFavorite = async () => {
    const newFav = !this.state.fav;
    this.setState({ fav: newFav });
    if (newFav) {
      await API.post(
        'recipes',
        `/recipes/${this.props.match.params.id}/favorite`
      );
    } else {
      await API.del(
        'recipes',
        `/recipes/${this.props.match.params.id}/favorite`
      );
    }
    setTimeout(() => ReactTooltip.hide(), 700);
  };

  copy = async () => {
    this.setState({ copying: true }, async () =>
      setTimeout(() => this.setState({ copying: false }), 1250)
    );
    let copyText = this.state.recipe.recipeName;
    copyText = this.state.recipe.noPortions
      ? copyText.concat('\n', this.state.recipe.noPortions)
      : copyText;
    copyText = copyText.concat(
      `\n\nIngredienser:\n${this.state.recipe.ingredients}\n\nGör så här:\n${this.state.recipe.description}`
    );
    copy(copyText);
  };

  render() {
    return (
      <div className="Recipes">
        {this.state.recipe && (
          <div>
            <div className="recipe_header">
              <div className="recipe_name">
                <h4>{this.state.recipe.recipeName}</h4>
                <h6>
                  {this.state.recipe.noPortions
                    ? this.state.recipe.noPortions
                    : undefined}
                </h6>
                {!this.state.ownRecipe && (
                  <h6>
                    Recept av{' '}
                    <Link to={`/?username=${this.state.ownerUsername}`}>
                      {this.state.ownerUsername}
                    </Link>
                  </h6>
                )}
              </div>
              {this.state.ownRecipe && (
                <div className="favorite">
                  <Button
                    variant="none"
                    onClick={this.toggleFavorite}
                    data-tip={
                      this.state.fav
                        ? 'Avmarkera som favorit'
                        : 'Favoritmarkera recept'
                    }
                  >
                    {this.state.fav ? (
                      <IoIosHeart color="#17a2b8" size="35" />
                    ) : (
                      <IoIosHeartEmpty color="#17a2b8" size="35" />
                    )}
                  </Button>
                </div>
              )}
              <div className="copy">
                <Button
                  variant={this.state.copying && 'info'}
                  onClick={this.copy}
                  data-tip="Kopiera recept till urklipp"
                >
                  <IoMdCopy
                    color={this.state.copying ? 'white' : '#17a2b8'}
                    size="35"
                  />
                </Button>
              </div>

              <ReactTooltip />
            </div>
            <hr style={{ marginTop: 0 }} />
            <div className="Img">
              {this.state.imageURL && (
                <img
                  src={this.state.imageURL}
                  className="recipe_img"
                  alt="Recipe img"
                />
              )}
            </div>
            <div className="Ingr">
              <h5>Ingredienser:</h5>
              {this.state.recipe.ingredients}
              <br />
              <br />
            </div>
            <div className="Desc">
              <h5>Gör så här:</h5>
              <Linkify componentDecorator={this.componentDecorator}>
                {this.state.recipe.description}
              </Linkify>
              <hr />
            </div>
            <div className="Cats">
              {this.state.recipe.mainIngredient.length > 0 && (
                <div>
                  <h6>Huvudingredienser</h6>
                  {this.state.recipe.mainIngredient.join(', ')}
                  <br />
                  <br />
                </div>
              )}{' '}
              {this.state.recipe.typeOfRecipe.length > 0 && (
                <div>
                  <h6>Typ av recept</h6>
                  {this.state.recipe.typeOfRecipe.join(', ')}
                  <br />
                  <br />
                </div>
              )}{' '}
              {this.state.recipe.typeOfDish.length > 0 && (
                <div>
                  <h6>Typ av rätt</h6>
                  {this.state.recipe.typeOfDish.join(', ')}
                  <br />
                  <br />
                </div>
              )}{' '}
              {this.state.recipe.sides.length > 0 && (
                <div>
                  <h6>Tillbehör</h6>
                  {this.state.recipe.sides.join(', ')}
                  <br />
                  <br />
                </div>
              )}{' '}
              {this.state.recipe.bake.length > 0 && (
                <div>
                  <h6>Bakning</h6>
                  {this.state.recipe.bake.join(', ')}
                  <br />
                  <br />
                </div>
              )}
            </div>
            {this.state.ownRecipe && (
              <div>
                <div className="update_recipe">
                  <LoaderButton
                    block
                    variant="info"
                    isLoading={this.state.isUpdating}
                    onClick={this.handleUpdate}
                    text="Uppdatera recept"
                  />
                </div>
                <div className="remove_recipe">
                  <LoaderButton
                    block
                    variant="info"
                    isLoading={this.state.isDeleting}
                    onClick={this.handleDelete}
                    text="Ta bort recept"
                  />
                </div>
              </div>
            )}
            {!this.state.ownRecipe && (
              <LoaderButton
                block
                variant="info"
                isLoading={this.state.isUpdating} //TODO Fix
                onClick={this.handleAdd} //TODO Fix
                text="Lägg till recept i min samling"
                disabled={!this.state.searchAllEnabled}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

Recipe.propTypes = {
  history: PropTypes.any,
  isAuthenticated: PropTypes.any,
  match: PropTypes.any,
};
