import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import LoaderButton from '../components/LoaderButton';
import { Form, Col, FormControl } from 'react-bootstrap';
import { API, Auth } from 'aws-amplify';
import PropTypes from 'prop-types';

import './Profile.css';
export const validCharacters = /^[A-Za-z0-9_-]*$/;

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      change: false,
      isUpdating: false,
      oldUsername: '',
      username: '',
      email: '',
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    if (!this.props.isAuthenticated) {
      this.props.history.push('/login');
      return;
    }

    const identityId = (await Auth.currentUserCredentials()).identityId;
    sessionStorage.setItem('currentIdentityId', identityId);

    const username = await this.getUsername();

    const email = (await Auth.currentAuthenticatedUser()).attributes.email;
    this.setState({
      username,
      oldUsername: username,
      email,
      isLoading: false,
    });
  }

  getUsername = async () => {
    return this.state.username
      ? this.state.username
      : (
          await API.get('recipes', '/username', {
            queryStringParameters: {
              username: sessionStorage.getItem('currentIdentityId'),
            },
          })
        ).userName;
  };

  validateForm() {
    return (
      this.state.username.match(validCharacters) &&
      this.state.username.length >= 4 &&
      this.state.username !== this.state.oldUsername
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleAbort = async (event) => {
    event.preventDefault();

    this.props.history.goBack();
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isUpdating: true });
    try {
      await API.put('recipes', `/username`, {
        body: {
          username: this.state.username,
        },
      });

      this.setState({ isUpdating: false, oldUsername: this.state.username });
    } catch (err) {
      if (err.response && err.response.data && err.response.data.Error)
        alert(err.response.data.Error);
      else alert(err);
      this.setState({ isUpdating: false });
    }
  };

  renderProfile() {
    return (
      <div className="profile_change">
        <h4>Min profil</h4>
        <Form
          onSubmit={this.handleSubmit}
          onReset={this.handleAbort}
          className="Profile"
        >
          <Form.Row>
            <Form.Group as={Col} xs="12" sm="8" md="6" controlId="username">
              <Form.Label>Användarnamn</Form.Label>
              <FormControl
                onChange={this.handleChange}
                value={this.state.username}
                type="text"
                placeholder={this.state.username}
                isInvalid={
                  !this.state.username.match(validCharacters) ||
                  this.state.username.length < 4
                }
              />
              <FormControl.Feedback type="invalid">
                Användarnamnet måste vara minst 4 tecken långt, och får endast
                innehålla bokstäver, siffror, under- och bindestreck
              </FormControl.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs="12" sm="8" md="6" controlId="email">
              <Form.Label>Email</Form.Label>
              <FormControl
                // onChange={this.handleChange}
                value={this.state.email}
                disabled={true}
                type="text"
                placeholder={this.state.email}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              xs="12"
              sm="8"
              md="6"
              controlId="changePasswordButton"
            >
              <Form.Label>Lösenord</Form.Label>
              <LinkContainer to="/profile/password">
                <LoaderButton block variant="outline-info">
                  Byt lösenord
                </LoaderButton>
              </LinkContainer>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs="12" sm="4" md="3" controlId="updateButton">
              <LoaderButton
                block
                variant="info"
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isUpdating}
                text="Uppdatera"
              />
            </Form.Group>
            <Form.Group as={Col} xs="12" sm="4" md="3" controlId="abortButton">
              <LoaderButton
                block
                variant="info"
                disabled={false}
                type="reset"
                text="Avbryt"
              />
            </Form.Group>
          </Form.Row>
        </Form>
        <hr />
      </div>
    );
  }

  render() {
    return (
      <div className="Profile">
        {!this.state.isLoading && this.renderProfile()}
      </div>
    );
  }
}

Profile.propTypes = {
  history: PropTypes.any,
  isAuthenticated: PropTypes.any,
};
