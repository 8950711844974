export const sortFun = (a, b) => {
  const aVal = a.value.toUpperCase();
  const bVal = b.value.toUpperCase();
  if (aVal < bVal) return -1;
  else if (bVal > aVal) return 1;
  return 0;
}

export const arrayUnique = array => {
  var a = array.concat();
  for(var i=0; i<a.length; ++i) {
    for(var j=i+1; j<a.length; ++j) {
      if(a[i] === a[j])
        a.splice(j--, 1);
    }
  }
  return a;
};