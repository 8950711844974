import React, { Component } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import LoaderButton from '../components/LoaderButton';
import { Auth, API } from 'aws-amplify';
import { Link } from 'react-router-dom';
import shortId from 'shortid';
import PropTypes from 'prop-types';
import './Login.css';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: '',
      password: '',
      newPassword: '',
      confirmNewPassword: '',
      changePassword: false,
      user: null,
      confirmationCode: '',
      confirmEmailAddress: false,
    };
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  validateNewPWForm() {
    return (
      this.state.email.length > 0 &&
      this.state.newPassword.length >= 8 &&
      this.state.confirmNewPassword.length >= 8 &&
      this.state.newPassword === this.state.confirmNewPassword &&
      this.state.newPassword.match(/.*[0-9]+.*/)
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]:
        event.target.id === 'email'
          ? event.target.value.toLowerCase().trim()
          : event.target.value.trim(),
    });
  };

  updateUsernameIfNeeded = async () => {
    const identityId = (await Auth.currentUserCredentials()).identityId;
    sessionStorage.setItem('currentIdentityId', identityId);
    const username = (
      await API.get('recipes', '/username', {
        queryStringParameters: {
          username: identityId,
        },
      })
    ).userName;
    if (!username || username.length === 0) {
      var updateUsername = true;
      var newName = prompt(`Ange ett användarnamn.\n
Användarnamnet måste vara minst 4 tecken långt, och får endast innehålla bokstäver, siffror, under- och bindestreck.
Om du inte väljer något så blir du tilldelat ett slumpmässigt genererat namn. Du kan själv ändra användarnamnet senare på din Profil.`);
      var attempt = 0;
      do {
        attempt = attempt + 1;
        try {
          await API.put('recipes', `/username`, {
            body: {
              username: newName,
            },
          });
          updateUsername = false;
        } catch (e) {
          if (attempt === 1) {
            this.setState({ userName: shortId.generate() });
            await API.put('recipes', `/username`, {
              body: {
                username: this.state.userName,
              },
            });
          }
          newName =
            prompt(`Användarnamnet du angav är upptaget eller felaktigt, välj ett annat.\n
Användarnamnet måste vara minst 4 tecken långt, och får endast innehålla bokstäver, siffror, under- och bindestreck.
Om du inte väljer något så blir du tilldelat ett slumpmässigt genererat namn. Du kan själv ändra användarnamnet senare på din Profil.`);
          if (newName === null || newName === '') updateUsername = false;
          else this.setState({ userName: newName });
        }
      } while (updateUsername);
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const user = await Auth.signIn(this.state.email, this.state.password);
      await this.updateUsernameIfNeeded();
      if (user.challengeName) {
        this.setState({ isLoading: false });
        this.setState({ changePassword: true });
        this.setState({ user });
      } else {
        this.props.userHasAuthenticated(true);
        this.props.history.push('/');
      }
    } catch (e) {
      if (e.code === 'UserNotConfirmedException')
        this.setState({ confirmEmailAddress: true, changePassword: false });
      else alert('Något gick fel, försök igen');
      this.setState({ isLoading: false });
    }
  };

  handleChangePWSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.completeNewPassword(this.state.user, this.state.newPassword);
      this.props.userHasAuthenticated(true);
      this.props.history.push('/');
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  handleConfirmationSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      this.setState({ confirmEmailAddress: false, isLoading: false });
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  resendCode = () => {
    this.setState({ isSendingConfirmation: true });
    Auth.resendSignUp(this.state.email);
    this.setState({ isSendingConfirmation: false });
  };

  render() {
    return this.state.confirmEmailAddress ? (
      <div className="Login">
        <Form onSubmit={this.handleConfirmationSubmit}>
          <h5> Din email måste bekräftas innan du kan logga in </h5>
          <Form.Group controlId="confirmationCode">
            <Form.Label>Verifieringskod</Form.Label>
            <Form.Control
              autoFocus
              type="tel"
              onChange={this.handleChange}
              value={this.state.confirmationCode}
            />
            <Form.Text>
              Vänligen kolla din email ({this.state.email}) för verifieringskod.
            </Form.Text>
            <hr />
            <LoaderButton
              block
              variant="outline-info"
              isLoading={this.state.isSendingConfirmation}
              type="button"
              onClick={this.resendCode}
            >
              Saknar du din kod?
            </LoaderButton>
          </Form.Group>
          <LoaderButton
            block
            variant="info"
            type="submit"
            isLoading={this.state.isLoading}
            disabled={!this.validateConfirmationForm()}
          >
            Verifiera
          </LoaderButton>
        </Form>
      </div>
    ) : !this.state.changePassword ? (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Lösenord</Form.Label>
            <Form.Control
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </Form.Group>
          <Link to="/login/reset">Glömt lösenord?</Link>
          <hr />
          <LoaderButton
            block
            variant="info"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Logga in"
          />
        </form>
      </div>
    ) : (
      <div className="Login">
        <form onSubmit={this.handleChangePWSubmit}>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group controlId="newPassword">
            <Form.Label>Nytt lösenord</Form.Label>
            <Form.Control
              value={this.state.newPassword}
              onChange={this.handleChange}
              type="password"
              isInvalid={
                this.state.newPassword.length > 0 &&
                (this.state.newPassword.length < 8 ||
                  !this.state.newPassword.match(/.*[0-9]+.*/))
              }
            />
            <FormControl.Feedback type="invalid">
              Lösenordet måste innehålla minst 8 tecken, varav åtminstone 1
              siffra
            </FormControl.Feedback>
          </Form.Group>
          <Form.Group controlId="confirmNewPassword">
            <Form.Label>Verifiera nytt lösenord</Form.Label>
            <Form.Control
              value={this.state.confirmNewPassword}
              onChange={this.handleChange}
              type="password"
            />
          </Form.Group>
          <hr />
          <LoaderButton
            block
            variant="info"
            disabled={!this.validateNewPWForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Logga in"
          />
        </form>
      </div>
    );
  }
}

Login.propTypes = {
  history: PropTypes.any,
  userHasAuthenticated: PropTypes.any,
};
