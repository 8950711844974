import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AppliedRoute from './components/AppliedRoute';
import Home from './containers/Home';
import Login from './containers/Login';
import NewRecipe from './containers/NewRecipe';
import Recipe from './containers/Recipes';
import ResetPassword from './containers/ResetPassword';
import Categories from './containers/Categories';
import Profile from './containers/Profile';
import ChangePassword from './containers/ChangePassword';
import Register from './containers/Register';
import PropTypes from 'prop-types';
import NotFound from './containers/NotFound';

const Routes = ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/login" exact component={Login} props={childProps} />
    <AppliedRoute
      path="/recipes/new"
      exact
      component={NewRecipe}
      props={childProps}
    />
    <AppliedRoute
      path="/recipes/:userId/:id"
      exact
      component={Recipe}
      props={childProps}
    />
    <AppliedRoute
      path="/recipes/:userId/:id/update"
      exact
      component={NewRecipe}
      props={childProps}
    />
    <AppliedRoute
      path="/login/reset"
      exact
      component={ResetPassword}
      props={childProps}
    />
    <AppliedRoute
      path="/categories"
      exact
      component={Categories}
      props={childProps}
    />
    <AppliedRoute
      path="/profile"
      exact
      component={Profile}
      props={childProps}
    />
    <AppliedRoute
      path="/profile/password"
      exact
      component={ChangePassword}
      props={childProps}
    />
    <AppliedRoute
      path="/signup"
      exact
      component={Register}
      props={childProps}
    />

    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);

Routes.propTypes = {
  childProps: PropTypes.any,
};

export default Routes;
