import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Form, FormControl, Col } from 'react-bootstrap';
import { API, Auth, Storage } from 'aws-amplify';
import LoaderButton from '../components/LoaderButton';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';
import config from '../config';
import './NewRecipe.css';
import { arrayUnique, sortFun } from '../utils/CategoryUtils';
import PropTypes from 'prop-types';

export default class NewRecipe extends Component {
  constructor(props) {
    super(props);
    this.id = this.props.match.params.id;

    this.file = null;
    this.saveText = this.id ? 'Uppdatera recept' : 'Skapa recept';
    this.state = {
      isLoading: true,
      isSaving: null,
      recipeName: '',
      description: '',
      ingredients: '',
      preText: '',
      noPortions: '',
      msg: '',
      fileName: '',
      typeOfDish: [],
      typeOfRecipe: [],
      mainIngredient: [],
      sides: [],
      bake: [],
      mainIngredientOptions: [],
      sidesOptions: [],
      bakeOptions: [],
      todOptions: [],
      torOptions: [],
      image: '',
      imageURL: '',
      origImage: undefined,
      origImageURL: undefined,
      inputKey: Date.now(),
      resetting: false,
    };
  }

  async componentDidMount() {
    let auth = true;
    try {
      auth = this.props.isAuthenticated && (await Auth.currentSession());
    } catch (err) {
      auth = false;
    }
    if (!auth) {
      this.props.history.push('/login');
      return;
    }
    const meta = await this.getMetadata();

    this.setState({
      sidesOptions: meta.sides
        .map((val) => {
          return { value: val, label: val };
        })
        .sort(sortFun),
      bakeOptions: meta.bake
        .map((val) => {
          return { value: val, label: val };
        })
        .sort(sortFun),
      mainIngredientOptions: meta.mainIngredient
        .map((val) => {
          return { value: val, label: val };
        })
        .sort(sortFun),
      todOptions: meta.typeOfDish
        .map((val) => {
          return { value: val, label: val };
        })
        .sort(sortFun),
      torOptions: meta.typeOfRecipe
        .map((val) => {
          return { value: val, label: val };
        })
        .sort(sortFun),
    });

    if (this.id) {
      let recipe;
      try {
        recipe = await this.getRecipe();
      } catch (err) {
        alert(err);
        this.props.history.goBack();
        return;
      }

      let imageURL = undefined;
      if (!sessionStorage.getItem('currentIdentityId')) {
        const identityId = (await Auth.currentUserCredentials()).identityId;
        sessionStorage.setItem('currentIdentityId', identityId);
      }
      const { image } = recipe;

      if (image) {
        if (typeof image === 'string') {
          const imageParts = image.split('/');
          imageURL = await Storage.get(imageParts.pop(), {
            level: 'protected',
            identityId: imageParts.pop(),
          });
        }
      }
      this.setState({
        ...recipe,
        imageURL,
        origImageURL: imageURL,
        origImage: recipe.image,
      });
    }

    this.setState({ isLoading: false });
  }

  getMetadata = async () => {
    const meta = await API.get('recipes', '/metadata');
    return {
      sides: arrayUnique(
        (meta.categories.sides || []).concat(meta.defaultCategories.sides)
      ),
      bake: arrayUnique(
        (meta.categories.bake || []).concat(meta.defaultCategories.bake)
      ),
      mainIngredient: arrayUnique(
        (meta.categories.mainIngredient || []).concat(
          meta.defaultCategories.mainIngredient
        )
      ),
      typeOfDish: arrayUnique(
        (meta.categories.typeOfDish || []).concat(
          meta.defaultCategories.typeOfDish
        )
      ),
      typeOfRecipe: arrayUnique(
        (meta.categories.typeOfRecipe || []).concat(
          meta.defaultCategories.typeOfRecipe
        )
      ),
    };
  };

  getRecipe() {
    return API.get('recipes', `/recipes/${this.props.match.params.id}`);
  }

  validateForm() {
    return (
      this.state.description.length > 0 && this.state.recipeName.length > 0
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleFileChange = (event) => {
    if (event.target.files[0]) {
      this.file = event.target.files[0];
      this.setState({
        image: undefined,
        fileName: this.file.name,
        imageURL: URL.createObjectURL(this.file),
      });
    }
  };

  handleMainIngredientChange = (newValue) => {
    this.handleOptionsChange(newValue, 'mainIngredient');
  };

  handleTodChange = (newValue) => {
    this.handleOptionsChange(newValue, 'typeOfDish');
  };

  handleTorChange = (newValue) => {
    this.handleOptionsChange(newValue, 'typeOfRecipe');
  };

  handleSidesChange = (newValue) => {
    this.handleOptionsChange(newValue, 'sides');
  };

  handleBakeChange = (newValue) => {
    this.handleOptionsChange(newValue, 'bake');
  };

  handleOptionsChange = async (newValue, metaName) => {
    await this.setState({
      [metaName]: newValue ? newValue.map((val) => val.label) : [],
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      this.showAlert(
        `Välj en bild som är mindre än ${config.MAX_ATTACHMENT_SIZE / 1000} kB.`
      );
      return;
    }

    this.setState({ isSaving: true });
    const r = {
      recipeName: this.state.recipeName,
      description: this.state.description,
      ingredients: this.state.ingredients,
      noPortions: this.state.noPortions,
      preText: this.state.preText,
      typeOfDish: this.state.typeOfDish,
      typeOfRecipe: this.state.typeOfRecipe,
      mainIngredient: this.state.mainIngredient,
      sides: this.state.sides,
      bake: this.state.bake,
    };
    if (this.id) {
      try {
        r.image = this.state.image;
        await this.saveRecipe(r, true);
        sessionStorage.removeItem('recipesFetchTime');
        sessionStorage.removeItem('recipes');
        this.props.history.push(
          `/recipes/${this.props.match.params.userId}/${this.id}`
        );
      } catch (e) {
        this.showAlert(e);
        this.setState({ isSaving: false });
      }
    } else {
      try {
        await this.saveRecipe(r);
        sessionStorage.removeItem('recipesFetchTime');
        sessionStorage.removeItem('recipes');
        this.props.history.push('/');
      } catch (e) {
        this.showAlert(e);
        this.setState({ isSaving: false });
      }
    }
  };

  stripRecipe(recipe) {
    Object.keys(recipe).forEach((key) => {
      if (!recipe[key]) {
        delete recipe[key];
      }
    });
  }

  showAlert = (e) => {
    if (e.response && e.response.data && e.response.data.error) {
      alert('Failed to create recipe: ' + e.response.data.error);
    } else {
      alert(e);
    }
  };

  showOldImage = () => this.state.imageURL && !this.file;
  origImageRemoved = () => this.state.origImage && !this.state.image;

  async saveRecipe(recipe, isUpdate = false) {
    if (this.file) {
      console.log(this.file);

      const presigned = await this.createPresignedURL(this.file);
      console.log('OK, presigned:', presigned);
      await this.uploadFileToS3(presigned.data, this.file);
      recipe.image = presigned.data.fields.key;
    } else {
      if (recipe.image) console.log('Old image', recipe.image);
      else console.log('No image');
    }

    this.stripRecipe(recipe);
    if (isUpdate) {
      return API.put('recipes', `/recipes/${this.id}`, {
        body: recipe,
      });
    } else {
      return API.post('recipes', '/recipes', {
        body: recipe,
      });
    }
  }

  createPresignedURL(image) {
    return API.post('recipes', '/recipes/presignUrl', {
      body: {
        name: image.name,
        type: image.type,
      },
    });
  }

  /**
   * Upload file to S3 with previously received pre-signed POST data.
   * @param presignedPostData
   * @param file
   * @returns {Promise<any>}
   */
  uploadFileToS3 = (presignedPostData, file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      //  formData.append("Content-Type", file.type);
      Object.entries(presignedPostData.fields).forEach(([k, v]) => {
        formData.append(k, v);
      });

      // Actual file has to be appended last.
      formData.append('file', file);

      return fetch(presignedPostData.url, {
        method: 'POST',
        body: formData,
      })
        .then(() => resolve())
        .catch((e) => reject('Failed', e));
    });
  };

  onClearFile = () => {
    this.file = null;
    this.setState(
      {
        resetting: true,
        inputKey: Date.now(),
        fileName: undefined,
      },
      () => {
        this.setState({ resetting: false });
      }
    );
    if (this.props.onChange) this.props.onChange(null);
  };

  onRemoveImage = () => {
    this.onClearFile();
    this.setState({
      image: undefined,
      imageURL: undefined,
    });
  };

  onUseOldImage = () => {
    this.setState({
      image: this.state.origImage,
      imageURL: this.state.origImageURL,
    });
    this.onClearFile();
  };

  onAbort = () => {
    this.props.history.goBack();
  };

  formatCreateLabel = (input) => `Skapa ny kategori "${input}"`;

  renderForm() {
    return (
      <Form onSubmit={this.handleSubmit} className="NewRecipe">
        <Form.Group controlId="recipeName">
          <Form.Label>Receptnamn</Form.Label>
          <FormControl
            onChange={this.handleChange}
            value={this.state.recipeName}
            type="text"
          />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId="noPortions" md="4">
            <Form.Label>Antal portioner</Form.Label>
            <FormControl
              onChange={this.handleChange}
              value={this.state.noPortions}
              type="text"
            />
          </Form.Group>
          <Form.Group as={Col} controlId="preText">
            <Form.Label>Eventuell beskrivning</Form.Label>
            <FormControl
              onChange={this.handleChange}
              value={this.state.preText}
              type="text"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="ingredients" md="4">
            <Form.Label>Ingredienser</Form.Label>
            <FormControl
              onChange={this.handleChange}
              value={this.state.ingredients}
              as="textarea"
            />
          </Form.Group>
          <Form.Group as={Col} controlId="description">
            <Form.Label>Gör så här</Form.Label>
            <Form.Control
              onChange={this.handleChange}
              value={this.state.description}
              as="textarea"
            />
          </Form.Group>
        </Form.Row>
        <hr />
        <Form.Label>Bild</Form.Label>
        {this.state.imageURL && (
          <Form.Row>
            <Form.Group as={Col} xs="12" md="4">
              <div className="Img">
                {
                  <img
                    src={this.state.imageURL}
                    className="recipe_img"
                    alt="Recipe img"
                  />
                }
              </div>
            </Form.Group>
          </Form.Row>
        )}
        <Form.Row>
          {!this.state.resetting && (
            <Form.Group controlId="file" as={Col} xs="12" sm="12" md="4" lg="3">
              <input
                type="file"
                name="imgchoose"
                id="imgchoose"
                className="imgchooser"
                onChange={this.handleFileChange}
              />
              <label htmlFor="imgchoose">
                {this.state.origImageURL || this.state.fileName
                  ? 'Byt bild'
                  : 'Lägg till bild'}
              </label>
            </Form.Group>
          )}
          {this.state.imageURL && (
            <Form.Group as={Col} xs="12" md="4" lg="3">
              <LoaderButton
                block
                variant="secondary"
                type="button"
                text="Ta bort bild"
                onClick={this.onRemoveImage}
              />
            </Form.Group>
          )}
          {this.origImageRemoved() && (
            <Form.Group as={Col} xs="12" md="4" lg="3">
              <LoaderButton
                block
                variant="secondary"
                type="button"
                text="Ångra (använd tidigare)"
                onClick={this.onUseOldImage}
              />
            </Form.Group>
          )}
        </Form.Row>
        <hr />
        <h5>
          Kategorier{' '}
          <IoIosInformationCircleOutline
            color="#17a2b8"
            size="20"
            data-tip="Tagga dina recept med kategorier för att<br/>
                  enklare kunna söka bland recepten senare.
                  <br/><br/>
                  
                  För att lägga till nya taggar, skriv in<br/>
                  det nya namnet i sökrutan.<br/><br/>

                  För att ta bort en tagg, ta bort den<br/>
                  från samtliga recept."
          />
          <ReactTooltip multiline={true} />
        </h5>
        <Form.Row>
          <Form.Group as={Col} controlId="tod" xs="12" md="6" lg="4">
            <Form.Label>Typ av rätt</Form.Label>
            <CreatableSelect
              isMulti
              name="tod"
              onChange={this.handleTodChange}
              options={this.state.todOptions}
              placeholder="Välj"
              formatCreateLabel={this.formatCreateLabel}
              closeMenuOnSelect={false}
              defaultValue={
                this.state.typeOfDish
                  ? this.state.typeOfDish.map((val) => {
                      return { label: val, value: val };
                    })
                  : []
              }
            />
          </Form.Group>
          <Form.Group as={Col} controlId="tor" xs="12" md="6" lg="4">
            <Form.Label>Typ av recept</Form.Label>
            <CreatableSelect
              isMulti
              name="tor"
              onChange={this.handleTorChange}
              options={this.state.torOptions}
              placeholder="Välj"
              formatCreateLabel={this.formatCreateLabel}
              closeMenuOnSelect={false}
              defaultValue={
                this.state.typeOfRecipe
                  ? this.state.typeOfRecipe.map((val) => {
                      return { label: val, value: val };
                    })
                  : []
              }
            />
          </Form.Group>
          <Form.Group as={Col} controlId="mainIngredient" xs="12" md="6" lg="4">
            <Form.Label>Huvudingredienser</Form.Label>
            <CreatableSelect
              isMulti
              name="mainIngredient"
              onChange={this.handleMainIngredientChange}
              options={this.state.mainIngredientOptions}
              placeholder="Välj"
              formatCreateLabel={this.formatCreateLabel}
              closeMenuOnSelect={false}
              defaultValue={
                this.state.mainIngredient
                  ? this.state.mainIngredient.map((val) => {
                      return { label: val, value: val };
                    })
                  : []
              }
            />
          </Form.Group>
          <Form.Group as={Col} controlId="sides" xs="12" md="6" lg="4">
            <Form.Label>Tillbehör</Form.Label>
            <CreatableSelect
              isMulti
              name="sides"
              onChange={this.handleSidesChange}
              options={this.state.sidesOptions}
              placeholder="Välj"
              formatCreateLabel={this.formatCreateLabel}
              closeMenuOnSelect={false}
              defaultValue={
                this.state.sides
                  ? this.state.sides.map((val) => {
                      return { label: val, value: val };
                    })
                  : []
              }
            />
          </Form.Group>
          <Form.Group as={Col} controlId="bake" xs="12" md="6" lg="4">
            <Form.Label>Bakning</Form.Label>
            <CreatableSelect
              isMulti
              name="bake"
              onChange={this.handleBakeChange}
              options={this.state.bakeOptions}
              placeholder="Välj"
              formatCreateLabel={this.formatCreateLabel}
              closeMenuOnSelect={false}
              defaultValue={
                this.state.bake
                  ? this.state.bake.map((val) => {
                      return { label: val, value: val };
                    })
                  : []
              }
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} xs="12" md="6">
            <LoaderButton
              block
              variant="info"
              // bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isSaving}
              text={this.saveText}
            />
          </Form.Group>
          <Form.Group as={Col} xs="12" md="6">
            <LoaderButton
              block
              variant="info"
              type="button"
              text="Avbryt"
              onClick={this.onAbort}
            />
          </Form.Group>
        </Form.Row>
      </Form>
    );
  }

  render() {
    return !this.state.isLoading && this.renderForm();
  }
}

NewRecipe.propTypes = {
  history: PropTypes.any,
  isAuthenticated: PropTypes.any,
  match: PropTypes.any,
  onChange: PropTypes.any,
};
