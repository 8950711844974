import React, { Component } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import LoaderButton from '../components/LoaderButton';
import { Auth, API } from 'aws-amplify';
import shortId from 'shortid';
import './Register.css';
import { validCharacters } from './Profile';
import PropTypes from 'prop-types';

export default class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: '',
      password: '',
      confirmPassword: '',
      confirmationCode: '',
      userName: '',
      newUser: null,
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: false,
      email: '',
      password: '',
      confirmPassword: '',
      confirmationCode: '',
      userName: '',
      newUser: null,
    });
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.userName.match(validCharacters) &&
      this.state.userName.length >= 4 &&
      this.state.password.length >= 8 &&
      this.state.confirmPassword.length >= 8 &&
      this.state.password === this.state.confirmPassword &&
      this.state.password.match(/.*[0-9]+.*/)
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]:
        event.target.id === 'email'
          ? event.target.value.toLowerCase().trim()
          : event.target.value.trim(),
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password,
        preferred_username: this.state.userName,
      });
      this.setState({ isLoading: false, newUser });
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  handleConfirmationSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      await Auth.signIn(this.state.email, this.state.password);

      var updateUsername = true;
      var attempt = 0;
      do {
        attempt = attempt + 1;
        try {
          await API.put('recipes', `/username`, {
            body: {
              username: this.state.userName,
            },
          });
          updateUsername = false;
        } catch (e) {
          if (attempt === 1) {
            this.setState({ userName: shortId.generate() });
            await API.put('recipes', `/username`, {
              body: {
                username: this.state.userName,
              },
            });
          }
          const newName =
            prompt(`Användarnamnet du angav är upptaget eller felaktigt, välj ett annat.\n
Användarnamnet måste vara minst 4 tecken långt, och får endast innehålla bokstäver, siffror, under- och bindestreck.
Om du inte väljer något så blir du tilldelat ett slumpmässigt genererat namn. Du kan själv ändra användarnamnet senare på din Profil.`);
          if (newName === null || newName === '') updateUsername = false;
          else this.setState({ userName: newName });
        }
      } while (updateUsername);

      this.props.userHasAuthenticated(true);
      this.props.history.push('/');
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  renderForm() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group controlId="userName">
          <Form.Label>Användarnamn</Form.Label>
          <Form.Control
            type="text"
            value={this.state.userName}
            onChange={this.handleChange}
            isInvalid={
              this.state.userName.length > 0 &&
              (!this.state.userName.match(validCharacters) ||
                this.state.userName.length < 4)
            }
          />
          <FormControl.Feedback type="invalid">
            Användarnamnet måste vara minst 4 tecken långt, och får endast
            innehålla bokstäver, siffror, under- och bindestreck
          </FormControl.Feedback>
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Lösenord</Form.Label>
          <Form.Control
            type="password"
            value={this.state.password}
            onChange={this.handleChange}
            isInvalid={
              this.state.password.length > 0 &&
              (this.state.password.length < 8 ||
                !this.state.password.match(/.*[0-9]+.*/))
            }
          />
          <FormControl.Feedback type="invalid">
            Lösenordet måste innehålla minst 8 tecken, varav åtminstone 1 siffra
          </FormControl.Feedback>
        </Form.Group>
        <Form.Group controlId="confirmPassword">
          <Form.Label>Bekräfta lösenord</Form.Label>
          <Form.Control
            type="password"
            onChange={this.handleChange}
            value={this.state.confirmPassword}
          />
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          variant="info"
          isLoading={this.state.isLoading}
          disabled={!this.validateForm()}
        >
          Registrera
        </LoaderButton>
      </Form>
    );
  }

  renderConfirmationForm() {
    return (
      <Form onSubmit={this.handleConfirmationSubmit}>
        <Form.Group controlId="confirmationCode">
          <Form.Label>Verifieringskod</Form.Label>
          <Form.Control
            autoFocus
            type="tel"
            onChange={this.handleChange}
            value={this.state.confirmationCode}
          />
          <Form.Text>
            Vänligen kolla din email ({this.state.email}) för verifieringskod.
          </Form.Text>
        </Form.Group>
        <LoaderButton
          block
          variant="info"
          type="submit"
          isLoading={this.state.isLoading}
          disabled={!this.validateConfirmationForm()}
        >
          Verifiera
        </LoaderButton>
      </Form>
    );
  }

  render() {
    return (
      <div className="Register">
        {this.state.newUser ? this.renderConfirmationForm() : this.renderForm()}
      </div>
    );
  }
}

Register.propTypes = {
  userHasAuthenticated: PropTypes.any,
  history: PropTypes.any,
};
