import React, { Fragment, useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import Routes from './Routes';
import { Auth } from 'aws-amplify';
import { IoIosHeart } from 'react-icons/io';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import './App.css';

function App(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [searchString, setSearchString] = useState('');
  const navigate = useHistory();

  const initializing = async () => {
    try {
      await Auth.currentSession();

      setIsAuthenticated(true);
    } catch (e) {
      console.log(e);
      if (e !== 'No current user' && e.code !== 'NotAuthorizedException') {
        alert('Något gick fel', e);
      }
    }

    setIsAuthenticating(false);
  };

  useEffect(() => {
    initializing();
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    let query = '/?';
    if (searchString.length > 2)
      query += `searchString=${searchString.replace(/[+]/g, '%2B')}`;
    else {
      alert('Minsta längd på sökordet är 3 bokstäver');
      return;
    }

    navigate.push(query);
    window.location.reload();
  };

  const handleChange = (event) => {
    setSearchString(event.target.value);
  };

  const handleLogout = async () => {
    await Auth.signOut();
    sessionStorage.clear();

    setIsAuthenticated(false);
    props.history.push('/login');
  };

  const childProps = {
    isAuthenticated: isAuthenticated,
    userHasAuthenticated: setIsAuthenticated,
  };

  return (
    !isAuthenticating && (
      <div className="App container">
        <Navbar bg="light" variant="light" expand="lg">
          <Navbar.Brand href="/">
            <img
              src="/SmaskensIkon.jpg"
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="Smaskens logo"
            />
            {' Smaskens'}
          </Navbar.Brand>
          {window.location.pathname.startsWith('/recipes/') && (
            <Nav.Link href="/?favorites=true">
              <IoIosHeart color="#17a2b8" size="28px" opacity="50%" />
            </Nav.Link>
          )}

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {isAuthenticated &&
                window.location.pathname.startsWith('/recipes/') && (
                  <Form onSubmit={handleSubmit} className="search-form">
                    <Form.Group xs="10" sm="10" controlId="searchString">
                      <Form.Control
                        onChange={handleChange}
                        value={searchString}
                        type="text"
                        placeholder="Sök"
                      />
                    </Form.Group>
                  </Form>
                )}
              <Nav.Link href="/">Hem</Nav.Link>

              {!isAuthenticated ? (
                <Fragment>
                  <Nav.Link href="/login">Logga in</Nav.Link>
                  <Nav.Link href="/signup">Registrera</Nav.Link>
                </Fragment>
              ) : (
                <Fragment>
                  <Nav.Link href="/profile">Min profil</Nav.Link>
                  <Nav.Link href="/recipes/new">Lägg till recept</Nav.Link>
                  <Nav.Link onClick={handleLogout}>Logga ut</Nav.Link>
                </Fragment>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes childProps={childProps} />
      </div>
    )
  );
}

App.propTypes = {
  history: PropTypes.any,
};

export default withRouter(App);
