import React from 'react';
import { Button } from 'react-bootstrap';
import { MdRotateRight } from 'react-icons/md';
import PropTypes from 'prop-types';
import './LoaderButton.css';

const LoaderButton = ({
  isLoading,
  text,
  className = '',
  disabled = false,
  ...props
}) => (
  <Button
    className={`LoaderButton ${className}`}
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading && <MdRotateRight color="white" className="spinning" />}
    {props.children || text}
  </Button>
);

LoaderButton.propTypes = {
  isLoading: PropTypes.any,
  text: PropTypes.any,
  className: PropTypes.any,
  disabled: PropTypes.any,
  children: PropTypes.any,
};

export default LoaderButton;
