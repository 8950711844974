import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Col, FormControl } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import { API } from 'aws-amplify';
import PropTypes from 'prop-types';

import './Footer.css';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      footer: props.children,
      aboutModalIsOpen: false,
      contactModalIsOpen: false,
      subject: '',
      comment: '',
      email: '',
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  openAboutModal = () => {
    this.setState({ aboutModalIsOpen: true, contactModalIsOpen: false });
  };
  closeAboutModal = () => {
    this.setState({ aboutModalIsOpen: false });
  };

  openContactModal = () => {
    this.setState({ contactModalIsOpen: true, aboutModalIsOpen: false });
  };
  closeContactModal = () => {
    this.setState({ contactModalIsOpen: false });
  };

  validateForm = () => {
    return (
      this.state.subject.length > 0 &&
      this.state.comment.length > 0 &&
      this.state.email.match(/.+@.+\..+/)
    );
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await API.post('recipes', '/contact', {
        body: {
          subject: this.state.subject.trim(),
          comment: this.state.comment.trim(),
          email: this.state.email.trim(),
        },
      });
      alert('Tack för din kommentar');
      this.closeContactModal();
      this.setState({ subject: '', comment: '', email: '' });
    } catch (err) {
      alert('Ett fel uppstod, försök igen senare');
    }
  };

  render() {
    return (
      <div className="Footer">
        <div className="phantomStyle" />
        <div className="footerStyle">
          <button className="linkButton" onClick={this.openAboutModal}>
            Om Smaskens
          </button>
          |
          <button className="linkButton" onClick={this.openContactModal}>
            Kontakt
          </button>
          <Modal
            show={this.state.aboutModalIsOpen}
            onHide={this.closeAboutModal}
          >
            <ModalHeader closeButton>
              <ModalTitle>Om Smaskens</ModalTitle>
            </ModalHeader>
            <ModalBody>
              Smaskens är en enkel receptdatabas som kan anpassas efter
              användarens egna önskemål. Användaren kan enkelt söka bland egna
              och andras recept, dela in sina recept i kategorier och dela med
              sig av dem till andra. Har du några frågor, förbättringsförslag
              eller övriga kommentarer är du välkommen att kontakta oss via
              <button className="linkButton" onClick={this.openContactModal}>
                kontaktformuläret
              </button>
              .
            </ModalBody>
          </Modal>
          <Modal
            show={this.state.contactModalIsOpen}
            onHide={this.closeContactModal}
          >
            <ModalHeader closeButton>
              <ModalTitle>Kontakta oss</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={this.handleSubmit} className="UpdateForm">
                <Form.Row>
                  <Form.Group as={Col} controlId="subject" xs="12">
                    <Form.Label>Ämne</Form.Label>
                    <FormControl
                      onChange={this.handleChange}
                      value={this.state.subject}
                      type="text"
                      placeholder="Ämne"
                      maxLength="100"
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="comment" xs="12">
                    <Form.Label>Kommentar</Form.Label>
                    <FormControl
                      onChange={this.handleChange}
                      value={this.state.comment}
                      as="textarea"
                      rows="8"
                      maxLength="1500"
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="email" xs="12">
                    <Form.Label>Din e-postadress</Form.Label>
                    <FormControl
                      onChange={this.handleChange}
                      value={this.state.email}
                      type="text"
                      placeholder="example@email.com"
                      maxLength="100"
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} xs="12" controlId="sendButton">
                    <Button
                      block
                      variant="info"
                      // bsSize="large"
                      disabled={!this.validateForm()}
                      type="submit"
                    >
                      Skicka
                    </Button>
                  </Form.Group>
                </Form.Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  children: PropTypes.any,
};

export default withRouter(Footer);
