import React, { Component } from 'react';
import Select from 'react-select';
import {
  ListGroup,
  ListGroupItem,
  Form,
  FormControl,
  Col,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import LoaderButton from '../components/LoaderButton';
import CreatableSelect from 'react-select/creatable';
import { Button } from 'react-bootstrap';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { IoIosHeartEmpty, IoIosHeart } from 'react-icons/io';
import { API, Storage, Auth } from 'aws-amplify';
import Pagination from 'react-js-pagination';
import Collapsible from 'react-collapsible';
import qs from 'query-string';
import { arrayUnique, sortFun } from '../utils/CategoryUtils';
import FeatureToggles from '../utils/FeatureToggles';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

import './Home.css';

const RECIPES_FETCH_TIME = 1000 * 60 * 10; //10 min
export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isSearching: false,
      recipes: [],
      currentRecipes: [],
      page: 1,
      pageSize: 20,
      searchString: '',
      typeOfDish: [],
      typeOfRecipe: [],
      mainIngredient: [],
      bake: [],
      sides: [],
      mainIngredientOptions: [],
      sidesOptions: [],
      bakeOptions: [],
      todOptions: [],
      torOptions: [],
      categoryOptions: { all: [], default: [] },
      searchAll: false,
      categoryHeader: 'Filter (0 valda)',
      sortBy: 'recipeName',
      fav: false,
      favorites: [],
      searchAllEnabled: false,
      favoritesInListEnabled: false,
      searchByUser: '',
      usernameOptions: [],
    };
  }
  recipesRef = null;

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    const identityId = (await Auth.currentUserCredentials()).identityId;
    sessionStorage.setItem('currentIdentityId', identityId);

    const searchString = qs.parse(this.props.location.search).searchString;
    if (searchString) this.setState({ searchString });

    let categorySearch = false;
    const categories = [
      'mainIngredient',
      'typeOfRecipe',
      'typeOfDish',
      'bake',
      'sides',
    ];
    await this.asyncForEach(categories, async (category) => {
      const query = qs.parse(this.props.location.search, {
        arrayFormat: 'comma',
      })[category];
      if (query && typeof query === 'object') {
        await this.handleOptionsChange(
          query.map((val) => {
            const decodedVal = val.replace(new RegExp('\\\\\\\\', 'g'), ',');
            return { value: decodedVal, label: decodedVal };
          }),
          category
        );
        categorySearch = true;
      } else if (query && typeof query === 'string') {
        categorySearch = true;
        const decodedVal = query.replace(new RegExp('\\\\\\\\', 'g'), ',');
        await this.handleOptionsChange(
          [{ value: decodedVal, label: decodedVal }],
          category
        );
      }
    });
    const page = Number.parseInt(
      qs.parse(this.props.location.search).page || 1
    );
    const pageSize = qs.parse(this.props.location.search).pageSize;
    const showFavorites =
      qs.parse(this.props.location.search).favorites === 'true';
    const searchByUser = qs.parse(this.props.location.search).username;
    if (searchByUser) {
      await this.handleUsernameChange({
        value: searchByUser,
        label: searchByUser,
      });
    }

    const [searchAllEnabled] = await Promise.all([
      FeatureToggles.feature('searchAll'),
      this.updateMeta(),
    ]);

    await new Promise((r) => setInterval(r, 1));
    const favoritesInListEnabled = await FeatureToggles.feature(
      'favoritesInList'
    );
    this.setState({ searchAllEnabled, favoritesInListEnabled });
    if (searchAllEnabled) {
      const searchAll = qs.parse(this.props.location.search).searchAll;
      if (searchAll && searchAll === 'true') {
        this.setState({
          searchAll: true,
        });
      }
    }

    if (searchString || categorySearch || searchByUser) {
      await this.performSearch(page, pageSize);
    } else {
      if (page) this.setState({ page });
      if (pageSize) this.setState({ pageSize });
      try {
        const recipes = await this.getRecipes();
        if (showFavorites) {
          this.setState({ fav: showFavorites });
          const favorites = this.state.favorites;
          const favs = recipes.filter((recipe) =>
            favorites.find((favorite) => favorite === recipe.recipeId)
          );
          this.updateRecipesState(favs);
          this.props.history.push('?favorites=true');
        } else {
          this.updateRecipesState(recipes);
        }
        await this.updateRecipesList();
      } catch (e) {
        alert(e.message);
      }
    }

    if (!this.state.searchAll) {
      this.setState({
        sidesOptions: this.state.categoryOptions.all.sides,
        bakeOptions: this.state.categoryOptions.all.bake,
        mainIngredientOptions: this.state.categoryOptions.all.mainIngredient,
        todOptions: this.state.categoryOptions.all.typeOfDish,
        torOptions: this.state.categoryOptions.all.typeOfRecipe,
      });
    } else {
      this.setState({
        sidesOptions: this.state.categoryOptions.default.sides,
        bakeOptions: this.state.categoryOptions.default.bake,
        mainIngredientOptions:
          this.state.categoryOptions.default.mainIngredient,
        todOptions: this.state.categoryOptions.default.typeOfDish,
        torOptions: this.state.categoryOptions.default.typeOfRecipe,
      });
    }

    this.setState({ isLoading: false });
  }

  getRecipes = async () => {
    const nowTime = Date.now();
    const recipesFetchTime = sessionStorage.getItem('recipesFetchTime');
    if (
      recipesFetchTime &&
      nowTime - recipesFetchTime < RECIPES_FETCH_TIME &&
      sessionStorage.getItem('recipes')
    ) {
      console.log(
        `Fetching recipes from sessionStorage. ${
          RECIPES_FETCH_TIME / 1000 -
          Math.round((nowTime - recipesFetchTime) / 1000)
        } seconds until next fetch from DB.`
      );
      return JSON.parse(sessionStorage.getItem('recipes'));
    } else {
      console.log('Fetching recipes from database.');
      sessionStorage.setItem('recipesFetchTime', nowTime);
      const recipes = (await this.recipes()).recipes;
      sessionStorage.setItem('recipes', JSON.stringify(recipes));
      return recipes;
    }
  };

  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  updateMeta = async () => {
    const meta = await API.get('recipes', '/metadata');

    this.setState({
      categoryOptions: {
        all: {
          sides: arrayUnique(
            (meta.categories.sides || []).concat(meta.defaultCategories.sides)
          )
            .map((val) => {
              return { value: val, label: val };
            })
            .sort(sortFun),
          bake: arrayUnique(
            (meta.categories.bake || []).concat(meta.defaultCategories.bake)
          )
            .map((val) => {
              return { value: val, label: val };
            })
            .sort(sortFun),
          mainIngredient: arrayUnique(
            (meta.categories.mainIngredient || []).concat(
              meta.defaultCategories.mainIngredient
            )
          )
            .map((val) => {
              return { value: val, label: val };
            })
            .sort(sortFun),
          typeOfDish: arrayUnique(
            (meta.categories.typeOfDish || []).concat(
              meta.defaultCategories.typeOfDish
            )
          )
            .map((val) => {
              return { value: val, label: val };
            })
            .sort(sortFun),
          typeOfRecipe: arrayUnique(
            (meta.categories.typeOfRecipe || []).concat(
              meta.defaultCategories.typeOfRecipe
            )
          )
            .map((val) => {
              return { value: val, label: val };
            })
            .sort(sortFun),
        },
        default: {
          sides: meta.defaultCategories.sides
            .map((val) => {
              return { value: val, label: val };
            })
            .sort(sortFun),
          bake: meta.defaultCategories.bake
            .map((val) => {
              return { value: val, label: val };
            })
            .sort(sortFun),
          mainIngredient: meta.defaultCategories.mainIngredient
            .map((val) => {
              return { value: val, label: val };
            })
            .sort(sortFun),
          typeOfDish: meta.defaultCategories.typeOfDish
            .map((val) => {
              return { value: val, label: val };
            })
            .sort(sortFun),
          typeOfRecipe: meta.defaultCategories.typeOfRecipe
            .map((val) => {
              return { value: val, label: val };
            })
            .sort(sortFun),
        },
      },
      favorites: meta.favorites,
    });
  };

  recipes() {
    return API.get('recipes', '/recipes');
  }

  updateRecipesState(recipes) {
    const sortAttr = this.state.sortBy;
    const order = sortAttr === 'recipeName' ? 1 : -1;
    recipes.sort((a, b) =>
      a[sortAttr] > b[sortAttr]
        ? order * 1
        : b[sortAttr] > a[sortAttr]
        ? order * -1
        : a.recipeName > b.recipeName
        ? 1
        : b.recipeName > a.recipeName
        ? -1
        : 0
    );
    this.setState({
      recipes,
    });
  }

  async recipeImage(recipe) {
    if (
      recipe.image &&
      typeof recipe.image === 'string' &&
      recipe.image.includes('protected')
    ) {
      const imageParts = recipe.image.split('/');
      const imageURL = await Storage.get(imageParts.pop(), {
        level: 'protected',
        identityId: imageParts.pop(),
      });
      return (
        <img src={imageURL} className="list_item_image" alt="Smaskens loggo" />
      );
    } else {
      return (
        <img
          src="/SmaskensIkon.jpg"
          className="list_item_image"
          alt="Smaskens logo"
        />
      );
    }
  }

  async updateRecipesList() {
    await new Promise((r) => setTimeout(r, 1));
    const end = this.state.pageSize * this.state.page;
    const start = end - this.state.pageSize;
    const recipeList = this.state.recipes.slice(
      start,
      end > this.state.recipes.length ? this.state.recipes.length : end
    );

    const currRecip = await Promise.all(
      [{}].concat(recipeList).map(async (recipe, i) => {
        const item_classname = `list_item${
          recipe.userId !== sessionStorage.getItem('currentIdentityId')
            ? '_other'
            : ''
        }`;
        const title_classname = `list_item_title${
          recipe.userId !== sessionStorage.getItem('currentIdentityId')
            ? '_other'
            : ''
        }`;
        return i !== 0 ? (
          <LinkContainer
            className={item_classname}
            key={recipe.recipeId}
            to={`/recipes/${recipe.userId}/${recipe.recipeId}`}
          >
            <ListGroupItem action>
              <div className="list_item_image_container">
                <div className="list_item_image_div">
                  {await this.recipeImage(recipe)}
                </div>
              </div>
              <div className={title_classname}>
                <h5>{recipe.recipeName}</h5>
              </div>
              {this.state.favorites.find(
                (favorite) => favorite === recipe.recipeId
              ) &&
                this.state.favoritesInListEnabled && (
                  <div className="list_item_fav">
                    <IoIosHeart
                      color="#17a2b8"
                      size="21px"
                      style={{ horizontalAlign: 'right', verticalAlign: 'top' }}
                    />
                  </div>
                )}
            </ListGroupItem>
          </LinkContainer>
        ) : (
          <LinkContainer key="new" to="/recipes/new">
            <ListGroupItem action>
              <h4>
                <b>{'\uFF0B'}</b> Lägg till recept
              </h4>
            </ListGroupItem>
          </LinkContainer>
        );
      })
    );
    this.setState({ currentRecipes: currRecip });
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Smaskens</h1>
        <p>Din personliga receptdatabas</p>
      </div>
    );
  }

  updateQuery = (attributeName) => {
    let query = '';

    if (
      this.props.location.search &&
      qs.parse(this.props.location.search)[attributeName]
    ) {
      query = `${this.props.location.search.replace(
        new RegExp(`${attributeName}=\\d*`),
        `${attributeName}=` + this.state[attributeName]
      )}`;
    } else if (this.props.location.search)
      query = `${this.props.location.search}&${attributeName}=${this.state[attributeName]}`;
    else query = `?${attributeName}=${this.state[attributeName]}`;
    return query;
  };

  async handlePageChange(pageNumber) {
    this.setState(
      { page: pageNumber },
      window.scrollTo(0, this.recipesRef.offsetTop)
    );
    await new Promise((r) => setInterval(r, 1));
    const query = this.updateQuery('page');
    this.props.history.push(query);
    await this.updateRecipesList();
  }

  validateForm() {
    return (
      this.state.searchString.trim().length > 2 ||
      this.state.typeOfRecipe.length > 0 ||
      this.state.typeOfDish.length > 0 ||
      this.state.bake.length > 0 ||
      this.state.sides.length > 0 ||
      this.state.mainIngredient.length > 0 ||
      this.state.searchByUser.length > 0
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleCheckboxChange = (event) => {
    this.setState({
      [event.target.id]: event.target.checked,
    });

    if (!event.target.checked) {
      this.setState({
        sidesOptions: this.state.categoryOptions.all.sides,
        bakeOptions: this.state.categoryOptions.all.bake,
        mainIngredientOptions: this.state.categoryOptions.all.mainIngredient,
        todOptions: this.state.categoryOptions.all.typeOfDish,
        torOptions: this.state.categoryOptions.all.typeOfRecipe,
      });
    } else {
      this.setState({
        sidesOptions: this.state.categoryOptions.default.sides,
        bakeOptions: this.state.categoryOptions.default.bake,
        mainIngredientOptions:
          this.state.categoryOptions.default.mainIngredient,
        todOptions: this.state.categoryOptions.default.typeOfDish,
        torOptions: this.state.categoryOptions.default.typeOfRecipe,
      });
    }
  };

  handleMainIngredientChange = (newValue) => {
    this.handleOptionsChange(newValue, 'mainIngredient');
  };

  handleTodChange = (newValue) => {
    this.handleOptionsChange(newValue, 'typeOfDish');
  };

  handleTorChange = (newValue) => {
    this.handleOptionsChange(newValue, 'typeOfRecipe');
  };

  handleSidesChange = (newValue) => {
    this.handleOptionsChange(newValue, 'sides');
  };

  handleBakeChange = (newValue) => {
    this.handleOptionsChange(newValue, 'bake');
  };

  handleUsernameChange = async (newValue) => {
    await this.setState({ searchByUser: newValue ? newValue.value : '' });
    this.setState({
      categoryHeader: `Filter (${this.getSelectedCategories()} valda)`,
    });
  };

  getSelectedCategories = () => {
    return (
      this.state.mainIngredient.length +
      this.state.typeOfDish.length +
      this.state.typeOfRecipe.length +
      this.state.bake.length +
      this.state.sides.length +
      (this.state.searchByUser ? 1 : 0)
    );
  };

  handleOptionsChange = async (newValue, metaName) => {
    await this.setState({ [metaName]: newValue ? newValue : [] });
    console.log('new state', this.state[metaName]);
    this.setState({
      categoryHeader: `Filter (${this.getSelectedCategories()} valda)`,
    });
  };

  handlePageSizeChange = async (newValue) => {
    this.setState({ pageSize: newValue.value, page: 1 });
    let query = this.updateQuery('pageSize');
    this.props.history.push(query);
    query = this.updateQuery('page');
    this.props.history.push(query);
    await this.updateRecipesList();
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    let query = '?';
    if (this.state.searchString.length > 0)
      query += `searchString=${this.state.searchString.replace(/[+]/g, '%2B')}`;
    const categories = [
      'mainIngredient',
      'typeOfRecipe',
      'typeOfDish',
      'bake',
      'sides',
    ];
    await this.asyncForEach(categories, async (category) => {
      if (this.state[category].length > 0)
        query += `&${category}=${this.state[category].map((val) =>
          val.label.replace(/[,]/g, '\\\\').replace('&', '%26')
        )}`;
    });
    if (this.state.searchAll) {
      query += '&searchAll=true';
    }
    if (this.state.searchByUser) {
      query += `&username=${this.state.searchByUser}`;
    }
    this.props.history.push(query);
    await this.performSearch();
  };

  performSearch = async (pageNumber = 1, pageSize = 20) => {
    this.setState({ fav: false });
    this.setState({ isSearching: true });
    this.setState({ isLoading: true });
    try {
      const recipes = (
        await this.searchRecipes({
          searchString: this.state.searchString.trim(),
          typeOfDish: this.state.typeOfDish.map((val) => val.label),
          typeOfRecipe: this.state.typeOfRecipe.map((val) => val.label),
          mainIngredient: this.state.mainIngredient.map((val) => val.label),
          sides: this.state.sides.map((val) => val.label),
          bake: this.state.bake.map((val) => val.label),
          searchAll: this.state.searchAll.toString(),
          username: this.state.searchByUser,
        })
      ).recipes;
      this.updateRecipesState(recipes);
      this.setState({
        page: parseInt(pageNumber),
        pageSize: parseInt(pageSize),
      });
      await this.updateRecipesList();
      this.setState({ isSearching: false });
      this.setState({ isLoading: false });
    } catch (e) {
      alert(e);
      this.setState({ isSearching: false });
      this.setState({ isLoading: false });
    }
    sessionStorage.setItem('searchString', this.state.searchString);
  };

  handleReset = async (event) => {
    event.preventDefault();
    this.setState({ searchString: '', isLoading: true });
    try {
      const recipes = await this.getRecipes();
      this.updateRecipesState(recipes);
      this.setState({
        typeOfDish: [],
        typeOfRecipe: [],
        mainIngredient: [],
        bake: [],
        sides: [],
        categoryHeader: `Filter (0 valda)`,
        page: 1,
        fav: false,
        searchAll: false,
        searchByUser: '',
      });
      await this.props.history.push('/');
      await this.updateRecipesList();
    } catch (e) {
      alert(e.message);
    }
    this.setState({ isLoading: false });
  };

  async searchRecipes(search) {
    const queryStringParameters = search;

    Object.keys(queryStringParameters).forEach((key) => {
      if (!queryStringParameters[key].length > 0) {
        delete queryStringParameters[key];
      }
    });

    return API.get('recipes', '/recipes', {
      queryStringParameters,
    });
  }

  handleSortByChange = async (val) => {
    await this.setState({ sortBy: val });
    this.updateRecipesState(this.state.recipes);
    await this.updateRecipesList();
  };

  toggleFavorite = async () => {
    const showFavorites = !this.state.fav;
    this.setState({ fav: showFavorites, searchAll: false });
    if (showFavorites) {
      const recipes = await this.getRecipes();
      const favorites = this.state.favorites;
      const favs = recipes.filter((recipe) =>
        favorites.find((favorite) => favorite === recipe.recipeId)
      );
      this.updateRecipesState(favs);
      this.setState({ page: 1 });
      this.props.history.push('?favorites=true');
    } else {
      this.props.history.push('?favorites=false');
      await this.handleSubmit({
        preventDefault: () => {
          console.log('preventing');
        },
      });
    }
    await this.updateRecipesList();
    setTimeout(() => ReactTooltip.hide(), 700);
  };

  renderRecipes() {
    return (
      <div className="recipes">
        <div>
          <h3>
            Sök{' '}
            <IoIosInformationCircleOutline
              color="#17a2b8"
              size="20"
              data-tip="Avdela med mellanslag för att<br>
                          åtminstone ett av sökorden ska<br>
                          finnas med i receptet. Använd<br>
                          plustecken (+) för att samtliga<br>
                          ord ska matchas."
              data-for="searchInfo"
            />
            <ReactTooltip multiline={true} id="searchInfo" />
          </h3>
          <Form
            onSubmit={this.handleSubmit}
            onReset={this.handleReset}
            className="SearchForm"
          >
            <Form.Row>
              <Form.Group as={Col} xs="10" sm="11" controlId="searchString">
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.searchString}
                  type="text"
                  placeholder=""
                />
              </Form.Group>
              <Form.Group as={Col} xs="2" sm="1" controlId="resetButton">
                <LoaderButton
                  block="true"
                  variant="outline-info"
                  type="reset"
                  text="X"
                />
              </Form.Group>
            </Form.Row>
            <Collapsible
              trigger={this.state.categoryHeader}
              triggerWhenOpen={this.state.categoryHeader}
              transitionTime={100}
              overflowWhenOpen="visible"
            >
              <Form.Row>
                <Form.Group as={Col} controlId="tod" xs="12" md="6" lg="4">
                  <Form.Label>Typ av rätt</Form.Label>
                  <Select
                    isMulti
                    name="tod"
                    onChange={this.handleTodChange}
                    value={this.state.typeOfDish}
                    options={this.state.todOptions}
                    placeholder="Välj"
                    closeMenuOnSelect={false}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="tor" xs="12" md="6" lg="4">
                  <Form.Label>Typ av recept</Form.Label>
                  <Select
                    isMulti
                    name="tor"
                    onChange={this.handleTorChange}
                    value={this.state.typeOfRecipe}
                    options={this.state.torOptions}
                    placeholder="Välj"
                    closeMenuOnSelect={false}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  controlId="mainIngredient"
                  xs="12"
                  md="6"
                  lg="4"
                >
                  <Form.Label>Huvudingredienser</Form.Label>
                  <Select
                    isMulti
                    name="mainIngredient"
                    onChange={this.handleMainIngredientChange}
                    value={this.state.mainIngredient}
                    options={this.state.mainIngredientOptions}
                    placeholder="Välj"
                    closeMenuOnSelect={false}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="sides" xs="12" md="6" lg="4">
                  <Form.Label>Tillbehör</Form.Label>
                  <Select
                    isMulti
                    name="sides"
                    onChange={this.handleSidesChange}
                    value={this.state.sides}
                    options={this.state.sidesOptions}
                    placeholder="Välj"
                    closeMenuOnSelect={false}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="bake" xs="12" md="6" lg="4">
                  <Form.Label>Bakning</Form.Label>
                  <Select
                    isMulti
                    name="bake"
                    onChange={this.handleBakeChange}
                    value={this.state.bake}
                    options={this.state.bakeOptions}
                    placeholder="Välj"
                    closeMenuOnSelect={false}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="username" xs="12" md="6" lg="4">
                  <Form.Label>Användare</Form.Label>
                  <CreatableSelect
                    isSearchable
                    isClearable
                    value={
                      this.state.searchByUser
                        ? {
                            label: this.state.searchByUser,
                            value: this.state.searchByUser,
                          }
                        : []
                    }
                    name="username"
                    onChange={this.handleUsernameChange}
                    options={this.state.usernameOptions}
                    formatCreateLabel={(input) => `Sök på ${input}`}
                    placeholder="Välj"
                    closeMenuOnSelect={true}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  controlId="changeCategory"
                  xs="12"
                  md="6"
                  lg="4"
                >
                  <div className="changeCatDiv">
                    <LoaderButton
                      block="true"
                      variant="outline-info"
                      href="categories"
                      text="Ändra kategorinamn"
                    />
                  </div>
                </Form.Group>
              </Form.Row>
            </Collapsible>
            {this.state.searchAllEnabled && (
              <Form.Group controlId="searchAll">
                <Form.Check
                  type="checkbox"
                  label="Inkludera andra användares recept"
                  onChange={this.handleCheckboxChange}
                  value={this.state.searchAll}
                  checked={this.state.searchAll}
                />
              </Form.Group>
            )}
            <Form.Row>
              <Form.Group as={Col} xs="10" md="2" controlId="searchButton">
                <LoaderButton
                  block="true"
                  variant="info"
                  // bsSize="large"
                  disabled={!this.validateForm()}
                  type="submit"
                  isLoading={this.state.isSearching}
                  text="Sök"
                />
              </Form.Group>
              <Form.Group as={Col} xs="2" md="1" controlId="favorites">
                <div className="favorite">
                  <Button
                    variant="none"
                    onClick={this.toggleFavorite}
                    data-tip={
                      this.state.fav ? 'Visa samtliga' : 'Visa favoriter'
                    }
                    data-for="favInfo"
                  >
                    {this.state.fav ? (
                      <IoIosHeart
                        color="#17a2b8"
                        size="28px"
                        style={{ verticalAlign: 'top' }}
                      />
                    ) : (
                      <IoIosHeartEmpty
                        color="#17a2b8"
                        size="28px"
                        style={{ verticalAlign: 'top' }}
                      />
                    )}
                  </Button>
                  <ReactTooltip id="favInfo" />
                </div>
              </Form.Group>
            </Form.Row>
          </Form>
        </div>
        <hr />
        <div>
          <div className="sortToolbar">
            <ButtonToolbar>
              <div className="sortLabel">Sortera:</div>
              <div className="sortButtons">
                <ToggleButtonGroup
                  type="radio"
                  name="sortBy"
                  value={this.state.sortBy}
                  onChange={this.handleSortByChange}
                >
                  <ToggleButton
                    size="sm"
                    variant="outline-secondary"
                    value={'recipeName'}
                  >
                    Namn
                  </ToggleButton>
                  <ToggleButton
                    size="sm"
                    variant="outline-secondary"
                    value={'modifiedTime'}
                  >
                    Uppdaterad
                  </ToggleButton>
                  <ToggleButton
                    size="sm"
                    variant="outline-secondary"
                    value={'addedTime'}
                  >
                    Tillagd
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </ButtonToolbar>
          </div>
          <div className="pageSizeToolbar">
            <ButtonToolbar>
              <div className="pageLabel">Visa:</div>
              <div className="pageSize">
                <Select
                  name="pageSizeSelect"
                  onChange={this.handlePageSizeChange}
                  isSearchable={false}
                  value={this.state.pageSize}
                  options={[10, 20, 40, 100].map((val) => {
                    return { value: val, label: val };
                  })}
                  placeholder={`${this.state.pageSize} per sida`}
                  closeMenuOnSelect={true}
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                />
              </div>
            </ButtonToolbar>
          </div>
          {!this.state.isLoading && (
            <div className="totalHits">
              <div className="totalHitsLabel">
                {`Totalt ${this.state.recipes.length} recept`}
              </div>
            </div>
          )}
          {!this.state.isLoading && (
            <div
              className="recipesContainer"
              ref={(ref) => (this.recipesRef = ref)}
            >
              <div>
                <ListGroup variant="flush">
                  {this.state.currentRecipes}
                </ListGroup>
              </div>
              <div>
                <Pagination
                  activePage={this.state.page}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.recipes.length}
                  pageRangeDisplayed={4}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated
          ? this.renderRecipes()
          : this.renderLander()}
      </div>
    );
  }
}

Home.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  isAuthenticated: PropTypes.any,
};
