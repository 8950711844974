import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Form, FormGroup, FormControl, Col } from 'react-bootstrap';
import LoaderButton from '../components/LoaderButton';
import PropTypes from 'prop-types';
import './ChangePassword.css';

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUpdating: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.history.push('/login');
      return;
    }
    this.setState({
      isUpdating: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  }

  validateForm() {
    return (
      this.state.oldPassword.length > 0 &&
      this.state.newPassword.length >= 8 &&
      this.state.newPassword === this.state.confirmPassword &&
      this.state.newPassword.match(/.*[0-9]+.*/)
    );
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isUpdating: true });

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        this.state.oldPassword,
        this.state.newPassword
      );

      this.props.history.push('/profile');
    } catch (error) {
      alert(
        `Det gick inne att uppdatera lösenordet på grund av:\n${error.message}`
      );
      this.setState({ isUpdating: false });
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleAbort = async (event) => {
    event.preventDefault();

    this.props.history.goBack();
  };

  renderChangePw() {
    return (
      <div className="ChangePassword">
        <Form onSubmit={this.handleSubmit} onReset={this.handleAbort}>
          <FormGroup controlId="oldPassword">
            <Form.Label>Gammalt lösenord</Form.Label>
            <FormControl
              type="password"
              onChange={this.handleChange}
              value={this.state.oldPassword}
            />
          </FormGroup>
          <hr />
          <FormGroup controlId="newPassword">
            <Form.Label>Nytt lösenord</Form.Label>
            <FormControl
              type="password"
              onChange={this.handleChange}
              value={this.state.newPassword}
              isInvalid={
                this.state.newPassword.length > 0 &&
                (this.state.newPassword.length < 8 ||
                  !this.state.newPassword.match(/.*[0-9]+.*/))
              }
            />
            <FormControl.Feedback type="invalid">
              Lösenordet måste innehålla minst 8 tecken, varav åtminstone 1
              siffra
            </FormControl.Feedback>
          </FormGroup>
          <FormGroup controlId="confirmPassword">
            <Form.Label>Bekräfta nytt lösenord</Form.Label>
            <FormControl
              type="password"
              onChange={this.handleChange}
              value={this.state.confirmPassword}
            />
          </FormGroup>
          <Form.Row>
            <Form.Group as={Col} xs="12" controlId="updateButton">
              <LoaderButton
                block
                variant="info"
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isChanging}
                text="Uppdatera lösenord"
              />
            </Form.Group>
            <Form.Group as={Col} xs="12" controlId="abortButton">
              <LoaderButton
                block
                variant="info"
                disabled={false}
                type="reset"
                text="Avbryt"
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    );
  }

  render() {
    return <div className="ChangePassword">{this.renderChangePw()}</div>;
  }
}

ChangePassword.propTypes = {
  history: PropTypes.any,
  isAuthenticated: PropTypes.any,
};
