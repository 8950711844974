import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Form, FormControl } from 'react-bootstrap';
import LoaderButton from '../components/LoaderButton';
import './ResetPassword.css';

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
      email: '',
      password: '',
      codeSent: false,
      confirmed: false,
      confirmPassword: '',
      isConfirming: false,
      isSendingCode: false,
    };
  }

  validateCodeForm() {
    return this.state.email.length > 0;
  }

  validateResetForm() {
    return (
      this.state.code.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]:
        event.target.id === 'email'
          ? event.target.value.toLowerCase().trim()
          : event.target.value.trim(),
    });
  };

  handleSendCodeClick = async (event) => {
    event.preventDefault();

    this.setState({ isSendingCode: true });

    try {
      await Auth.forgotPassword(this.state.email);
      this.setState({ codeSent: true });
    } catch (e) {
      alert(e.message);
      this.setState({ isSendingCode: false });
    }
  };

  handleConfirmClick = async (event) => {
    event.preventDefault();

    this.setState({ isConfirming: true });

    try {
      await Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.code,
        this.state.password
      );
      this.setState({ confirmed: true });
    } catch (e) {
      alert(e.message);
      this.setState({ isConfirming: false });
    }
  };

  renderRequestCodeForm() {
    return (
      <form onSubmit={this.handleSendCodeClick}>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
        </Form.Group>
        <LoaderButton
          block
          variant="info"
          type="submit"
          text="Skicka"
          isLoading={this.state.isSendingCode}
          disabled={!this.validateCodeForm()}
        />
      </form>
    );
  }

  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmClick}>
        <Form.Group controlId="code">
          <Form.Label>Verifieringskod</Form.Label>
          <Form.Control
            autoFocus
            type="tel"
            value={this.state.code}
            onChange={this.handleChange}
          />
          <Form.Text>
            Vänligen kolla din email ({this.state.email}) för verifieringskod.
          </Form.Text>
        </Form.Group>
        <hr />
        <Form.Group controlId="password">
          <Form.Label>Nytt lösenord</Form.Label>
          <Form.Control
            type="password"
            value={this.state.password}
            onChange={this.handleChange}
            isInvalid={
              this.state.password.length > 0 &&
              (this.state.password.length < 8 ||
                !this.state.password.match(/.*[0-9]+.*/))
            }
          />
          <FormControl.Feedback type="invalid">
            Lösenordet måste innehålla minst 8 tecken, varav åtminstone 1 siffra
          </FormControl.Feedback>
        </Form.Group>
        <Form.Group controlId="confirmPassword">
          <Form.Label>Verifiera nytt lösenord</Form.Label>
          <Form.Control
            type="password"
            onChange={this.handleChange}
            value={this.state.confirmPassword}
          />
        </Form.Group>
        <LoaderButton
          block
          variant="info"
          type="submit"
          text="Bekräfta"
          isLoading={this.state.isConfirming}
          disabled={!this.validateResetForm()}
        />
      </form>
    );
  }

  renderSuccessMessage() {
    return (
      <div className="success">
        <p>Ditt lösenord har återställts.</p>
        <p>
          <Link to="/login">
            Klicka här för att logga in med ditt nya lösenord.
          </Link>
        </p>
      </div>
    );
  }

  render() {
    return (
      <div className="ResetPassword">
        {!this.state.codeSent
          ? this.renderRequestCodeForm()
          : !this.state.confirmed
          ? this.renderConfirmationForm()
          : this.renderSuccessMessage()}
      </div>
    );
  }
}
