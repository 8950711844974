import { API } from "aws-amplify";

const FEATURE_TOGGLES_FETCH_TIME = 1000 * 60 * 60; //60 min
const ftOverride = {};
export default class FeatureToggles {

  static feature = async feature => {
    if (ftOverride[`${feature}`]) {
      return true;
    }
    const nowTime = Date.now();
    const ftFetchTime = sessionStorage.getItem("ftFetchTime");
    if (ftFetchTime &&
        nowTime - ftFetchTime < FEATURE_TOGGLES_FETCH_TIME &&
        sessionStorage.getItem("featureToggles")) {
      console.log(`Fetching featureToggles from sessionStorage. ${FEATURE_TOGGLES_FETCH_TIME/1000 - (Math.round((nowTime - ftFetchTime) / 1000))} seconds until next fetch from DB.`);
    } else {
      console.log('Fetching featureToggles from database.');
      sessionStorage.setItem("ftFetchTime", nowTime);
      const ft = await API.get('recipes', '/featureToggles');
      sessionStorage.setItem('featureToggles', JSON.stringify(ft.featureToggles));
    }
    return JSON.parse(sessionStorage.getItem('featureToggles'))[feature];
  }
}