const Config = {
  s3: {
    REGION: 'eu-west-1',
    BUCKET: 'smaskens-images',
  },
  apiGateway: {
    REGION: 'eu-west-1',
    URL: 'https://1n6d7j95j3.execute-api.eu-west-1.amazonaws.com/prod',
  },
  cognito: {
    REGION: 'eu-west-1',
    USER_POOL_ID: 'eu-west-1_k8c1qaTN1',
    APP_CLIENT_ID: 'd11nhj11fdrl8lrr8tldq19b3',
    IDENTITY_POOL_ID: 'eu-west-1:2361d102-284e-4fc2-a36e-9cfd19707937',
  },
  MAX_ATTACHMENT_SIZE: 5000000, //5000 kB
};
export default Config;
